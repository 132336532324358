/**
 * Преимущества лизинга
 */

import PropTypes from "prop-types";
import React from "react";

import { Box, Typography } from "@material-ui/core";
import { SectionContainer } from "@product-site-frontend/shared";
import { GatsbyImage } from "gatsby-plugin-image";

Item.propTypes = {
  description: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string,
};

function Item({ description, icon, title }) {
  return (
    <Box>
      <Box mb={2}>
        <Box alignItems="center" display="flex">
          <GatsbyImage
            alt=""
            image={icon.localFile.childImageSharp.gatsbyImageData}
            style={{ width: 80, height: 80, marginRight: 16, flexShrink: 0 }}
          />
          <Typography component="div" sx={{ textAlign: { lg: "left" } }} variant="h3">
            {title}
          </Typography>
        </Box>
      </Box>
      <Typography sx={{ mx: 2 }} variant="body1">
        {description}
      </Typography>
    </Box>
  );
}

LeasingAdvantages.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    cards: PropTypes.array,
  }),
};

export default function LeasingAdvantages({ content }) {
  return (
    <SectionContainer title={content.title}>
      <Box display="flex" flexWrap="wrap" mx={-3}>
        {content.cards.map((props, i) => (
          <Box key={i} mt={{ xs: i ? 3 : 0, lg: 0 }} px={3} width={{ xs: 1, lg: 1 / 3 }}>
            <Item {...props} />
          </Box>
        ))}
      </Box>
    </SectionContainer>
  );
}

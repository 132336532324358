import PropTypes from "prop-types";
import React from "react";

import { TextField } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/styles";

import AmountMaskInput from "../AmountMaskInput";

const useStyles = makeStyles({
  input: {
    marginBottom: "-2px",
  },
  fieldset: {
    borderBottomLeftRadius: 1,
    borderBottomRightRadius: 1,
    borderBottomColor: "hsl(0deg 0% 100% / 0%) !important",
  },
  slider: {
    paddingTop: 0,
  },
  track: {
    borderTopLeftRadius: 0,
  },
  rail: {
    borderTopRightRadius: 0,
  },
  mark: {
    display: "none",
  },
  thumb: {
    height: 16,
    width: 16,
    marginTop: -8,
    marginLeft: -8,
    background: `radial-gradient(circle, white 40%, currentColor 40%)`,
  },
  markLabel: {
    userSelect: "none",
    transform: "none",
    pointerEvents: "none",
    fontSize: 14,
    top: 10,
    "&[data-index='1']": {
      transform: "translateX(-100%)",
    },
  },
});

RangeInputField.propTypes = {
  defaultValue: PropTypes.number,
  marks: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.number, label: PropTypes.string })),
  mask: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  step: PropTypes.number,
  value: PropTypes.number,
};

export default function RangeInputField({
  defaultValue,
  marks,
  mask,
  max,
  min,
  onChange,
  step,
  value,
}) {
  const classes = useStyles();
  // const [value, setValue] = React.useState(defaultValue);

  const handleSliderChange = (event, newValue) => {
    onChange(newValue);
    // setValue(newValue);
  };

  const handleInputChange = value => {
    onChange(Number(value.replace(/[^0-9]/g, "")));
    // setValue(Number(value.replace(/[^0-9]/g, "")));
  };

  const handleBlur = () => {
    if (value < min) {
      onChange(min);
    } else if (value > max) {
      onChange(max);
    }
  };

  return (
    <div>
      <TextField
        InputProps={{
          inputComponent: AmountMaskInput,
          classes: {
            notchedOutline: classes.fieldset,
          },
        }}
        autoComplete="off"
        className={classes.input}
        fullWidth
        inputProps={{
          spellCheck: false,
          mask,
          inputMode: "numeric",
        }}
        onBlur={handleBlur}
        onChange={handleInputChange}
        value={String(value)}
        variant="outlined"
      />
      <Slider
        className={classes.a}
        classes={{
          root: classes.slider,
          track: classes.track,
          rail: classes.rail,
          thumb: classes.thumb,
          mark: classes.mark,
          markLabel: classes.markLabel,
        }}
        marks={marks}
        max={max}
        min={min}
        onChange={handleSliderChange}
        step={step}
        value={typeof value === "number" ? value : 0}
      />
    </div>
  );
}

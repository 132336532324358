import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/core';
import store from 'store2';
import { Provider } from 'use-http';

import FormApplication from './FormApplication';
import FormOtp from './FormOtp';
import FormPhoneChange from './FormPhoneChange';

export const AUTH_TOKEN_STORAGE_KEY = 'auth_token';
export const USER_PHONE_STORAGE_KEY = 'phone';

export const arenzaStorage = store.namespace('arenza');

const options = {
  headers: {
    Accept: 'application/json',
  },
  cachePolicy: 'no-cache',
};

const MODE_TO_COMPONENT = {
  application: FormApplication,
  otp: FormOtp,
  phone: FormPhoneChange,
};

ApplicationRoot.propTypes = {
  channel: PropTypes.string,
  onSuccess: PropTypes.func,
  url: PropTypes.string,
};
export default function ApplicationRoot({ channel, onSuccess, url }) {
  const [yandexCaptchaToken, setYandexCaptchaToken] = useState('');
  const [mode, setMode] = useState('application');

  const application = arenzaStorage.session('application');

  const Form = MODE_TO_COMPONENT[application ? mode : 'application'];

  useEffect(() => {
    if (!application) {
      setMode("application");
    }
  }, [mode, application]);

  return (
    <Provider options={options} url={url}>
      <Form {...{ setMode, application, channel, onSuccess, yandexCaptchaToken, setYandexCaptchaToken  }} />
      {process.env.NODE_ENV === 'development' && (
        <SwitchMode mode={mode} onChange={setMode} />
      )}
    </Provider>
  );
}

SwitchMode.propTypes = {
  mode: PropTypes.any,
  onChange: PropTypes.any,
};
function SwitchMode({ mode, onChange }) {
  function handleChange(event, value) {
    onChange(value);
  }

  return (
    <ToggleButtonGroup
      color="primary"
      exclusive
      onChange={handleChange}
      sx={{ mt: 7 }}
      value={mode}
    >
      <ToggleButton value="application">Заявка</ToggleButton>
      <ToggleButton value="otp">OTP</ToggleButton>
      <ToggleButton value="phone">Телефон</ToggleButton>
    </ToggleButtonGroup>
  );
}

import PropTypes from "prop-types";
import React from "react";

import { InputAdornment, Tooltip } from "@material-ui/core";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";

EndAdornmentTooltip.propTypes = {
  title: PropTypes.string,
};
export default function EndAdornmentTooltip({ title }) {
  if (!title) return null;

  return (
    <InputAdornment position="end">
      <Tooltip arrow enterTouchDelay={100} title={title}>
        <HelpOutlineRoundedIcon sx={{ cursor: "default" }} />
      </Tooltip>
    </InputAdornment>
  );
}

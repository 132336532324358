import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useRef } from "react";

import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, DialogContentText, Typography } from "@material-ui/core";
import { LoadingButton } from '@material-ui/lab';
import { ApplicationContext } from '@product-site-frontend/shared';
import getAppTrackingParams from '@product-site-frontend/shared/utils/getTrackingParams';
import { useLocation } from '@reach/router';
import { SmartCaptcha } from '@yandex/smart-captcha';
import qs from "querystringify";
import { useForm } from 'react-hook-form';
import store from 'store2';
import useFetch from 'use-http';
import * as yup from 'yup';

import AlertError from '../AlertError';
import { APPLICATION_FIELDS_CONFIG } from './fields-config';

export const AUTH_TOKEN_STORAGE_KEY = 'auth_token';
export const USER_PHONE_STORAGE_KEY = 'phone';

export const arenzaStorage = store.namespace('arenza');

// CRM-1649 disable subject_classificator param
const FIELDS_ORDER = [
  'full_name',
  'phone',
  'email',
  'inn',
  // 'subject_classificator',
  'amount',
  'promo_code',
  'attachments',
];

const DEFAULT_VALUES = {
  phone: '', // '+7 (962) 624-77-76',
  email: '', // 'test@example.com',
  full_name: '', // 'Мачнева Жанна',
  attachments: [],
  // subject_classificator_id: null,
  amount: '',
  promo_code: ''
};

FormApplication.propTypes = {
  channel: PropTypes.string,
  setMode: PropTypes.func,
  setYandexCaptchaToken: PropTypes.func,
  yandexCaptchaToken: PropTypes.string,
};
export default function FormApplication({ channel, setMode, setYandexCaptchaToken, yandexCaptchaToken }) {
  const utm_source = useRef(null);
  const utm_full = useRef(null);

  useEffect(() => {
    if (location.search.includes('utm_source')) {
      let urlParam = qs.parse(location.search);
      utm_source.current = urlParam.utm_source;
      utm_full.current = location.href;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: {
    amount,
    email,
    full_name,
    inn,
    phone,
    promo_code,
  } } = useContext(ApplicationContext);
  const location = useLocation();

  // const { data: classificators = [] } = useFetch("/dictionaries/subject_classificators", []);

  function buildStepFields() {
    return FIELDS_ORDER.map((name) => {
      // if (name === "subject_classificator") {
      //   return {
      //     ...APPLICATION_FIELDS_CONFIG[name],
      //     componentProps: {
      //       ...APPLICATION_FIELDS_CONFIG[name].componentProps,
      //       options: classificators,
      //     },
      //   };
      // }
      return APPLICATION_FIELDS_CONFIG[name];
    });
  }
  const stepFields = useMemo(buildStepFields, []);

  const schema = useMemo(() => {
    return yup
      .object()
      .shape(stepFields.reduce(
        (prev, curr) => ({ ...prev, [curr.name]: curr.validation }),
        {},
      ));
  }, [stepFields]);

  const formMethods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUES,
  });
  const {
    clearErrors,
    control,
    formState,
    getValues,
    handleSubmit,
    setError,
    setValue,
  } = formMethods;

  useEffect(() => setValue('amount', amount.toString()), [amount, setValue]);
  useEffect(() => setValue('inn', inn.toString()), [inn, setValue]);
  useEffect(() => setValue('phone', phone.toString()), [phone, setValue]);
  useEffect(() => setValue('full_name', full_name.toString()), [full_name, setValue]);
  useEffect(() => setValue('email', email.toString()), [email, setValue]);
  useEffect(() => setValue('promo_code', promo_code.toString()), [promo_code, setValue]);

  const fetchOptions = {
    cachePolicy: 'no-cache',
    onError: ({ error }) => {
      const values = getValues();
      Rollbar.error('Серверная ошибка при отправке заявки', values, error);
      if (error.name === '422') {
        Object.entries(response.data.errors).forEach(([fieldName, [message]]) =>
          setError(fieldName, { type: 'manual', message, shouldFocus: true }),
        );
      } else {
        setError('FORM_ERROR', { message: error.message });
      }
    },
  };
  const { data, loading, post, response } = useFetch(
    '/short_apps',
    fetchOptions,
  );

  useEffect(() => {
    if (response.ok) {
      arenzaStorage.session('application', data);
      if (window && window.dataLayer) {
        window.dataLayer.push({
          event: 'send_form_main',
          ea: 'sent_form',
          ec: 'sent_request1',
        });
      } else {
        Rollbar.error('dataLayer не найден');
      }
      if (window && window.ga) {
        window.ga(
          'send',
          'event',
          'button',
          'sent_request1',
          'Отправил форму "Оставить заявку!"',
        );
      } else {
        Rollbar.error('GA не найден');
      }
    }
  }, [response.ok, data]);

  async function onSubmit({ attachments, ...data }) {
    let formData = new FormData();

    formData.append('full_name', data.full_name);
    formData.append('phone', data.phone);
    formData.append('amount', data.amount);
    formData.append('email', data.email);
    formData.append('inn', data.inn);
    if (data?.promo_code?.trim()?.length > 0) {
      formData.append('promo_code', data.promo_code);
    }

    // if (data.subject_classificator) {
    //   formData.append('subject_classificator_id', data.subject_classificator.id);
    // }
    if (window?.roistat?.visit) {
      formData.append('roistat_id', window?.roistat?.visit);
    }

    attachments.forEach(file => {
      formData.append('attachments[]', file);
    });

    if (channel) {
      formData.append('channel', channel);
    } else if (utm_source.current === 'avito') {
      formData.append('channel', utm_source.current);
      formData.append('landing_url', utm_full.current);
    } else {
      const trackingParams = getAppTrackingParams(location);
      Object.entries(trackingParams).forEach(([key, value]) => {
        formData.append(key, value);
      });
    }

    Rollbar.configure({
      payload: {
        person: { id: data.phone, email: data.email },
      },
    });

    const result = await post(formData);

    if (response.ok) {
      arenzaStorage.session('application', result);
      setMode('otp');
    } else if (response.status === 422) {
      Object.entries(response.data.errors).forEach(([fieldName, [message]]) =>
        setError(fieldName, { type: 'manual', message, shouldFocus: true }),
      );
    } else {
      Rollbar.error('Ошибка при отправке заявки', result);

      if (response?.data?.error === 's3_file_upload_error') {
        setError('FORM_ERROR', {
          message: 'При загрузке файлов возникла ошибка. Для передачи вложений свяжитесь с нами по номеру: +7 495 125 4344',
        });
      } else {
        setError('FORM_ERROR', {
          message: JSON.stringify(result),
        });
      }
    }
  }

  const AttachmentStep = stepFields.slice(-1)[0];

  return (
    <>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        spellCheck="false"
      >
        {stepFields.slice(0, -1).map(({
          FieldComponent,
          componentProps,
          name,
        }) => (
          <FieldComponent
            control={control}
            key={name}
            name={name}
            {...componentProps}
          />
        ))}
        <DialogContentText sx={{ fontWeight: 400 }}>
          Для ускорения процесса рассмотрения вашей заявки, Вы можете{' '}
          прикрепить файлы коммерческих предложений от поставщиков{' '}
          <Typography color="primary.main" component="span" variant="inherit">
            (в&nbsp;любом формате)
          </Typography>
          .
        </DialogContentText>
        <AttachmentStep.FieldComponent
          control={control}
          key={AttachmentStep.name}
          name={AttachmentStep.name}
          {...AttachmentStep.componentProps}
        />
        <AlertError
          message={formState.errors?.FORM_ERROR?.message}
          onClose={clearErrors}
          open={formState.errors?.FORM_ERROR ? true : false}
        />

        <Box sx={{ margin: '24px 0' }}>
          <SmartCaptcha onSuccess={setYandexCaptchaToken} sitekey="ysc1_ukeyoNivym2HND6EMGFlIbLdbdmtpMHoOQ0MZ2CX092b1063" />
        </Box>

        <LoadingButton
          color="primary"
          disabled={!yandexCaptchaToken?.length}
          fullWidth
          loading={loading}
          size="large"
          sx={{ my: 4, mx: "auto" }}
          type="submit"
          variant="contained"
        >
          Подать заявку
        </LoadingButton>
        <DialogContentText>
          <Typography variant="caption">
            Отправляя форму, вы даете согласие на обработку{" "}
            <Typography
              color="secondary"
              component="a"
              href="https://public-documents.arenza.ru/политика_обработки_персональных_данных.pdf"
              variant="inherit"
            >
              персональных данных
            </Typography>
            , указанных вами в заявке, в соответствии с требованиями{' '}
            Федерального закона №&nbsp;152-ФЗ от 27.07.2006{' '}
            «О персональных данных».
          </Typography>
        </DialogContentText>
      </form>
      <DevTool control={control} />
    </>
  );
}

export const typography = {
  fontFamily: ["Museo Sans", "Helvetica", "sans-serif"].join(","),
  h1: {
    // textTransform: "uppercase",
    fontWeight: 900,
    fontSize: 56,
    "&.MuiTypography-gutterBottom": {
      marginBottom: 40,
    },
  },
  h2: {
    fontSize: 36,
    fontWeight: 900,
    "&.MuiTypography-gutterBottom": {
      marginBottom: 32,
    },
  },
  h3: {
    fontSize: 22,
    fontWeight: 700,
    "&.MuiTypography-gutterBottom": {
      marginBottom: 16,
    },
  },
  h4: {
    fontSize: 18,
    fontWeight: 500,
    "&.MuiTypography-gutterBottom": {
      marginBottom: 16,
    },
  },
  // h5: {
  //   fontSize: 16,
  //   fontWeight: 500,
  //   "&.MuiTypography-gutterBottom": {
  //     marginBottom: 16,
  //   },
  // },
  subtitle1: {
    fontSize: 20,
    lineHeight: 1.6,
    fontWeight: 300,
  },
  subtitle2: {
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: 500,
  },
  body1: {
    fontWeight: 300,
  },
  body2: {
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: 400,
  },
  button: {
    fontWeight: 700,
  },
  overline: {
    textDecoration: "none",
  },
  link: {
    display: "inline-flex",
    textDecoration: "none",
    fontWeight: 500,
    transition: "opacity ease-in 0.2s",
    fontSize: 16,
    "&:hover": {
      opacity: 0.75,
    },
  },
};

import PropTypes from "prop-types";
import React from "react";

import { Box } from "@material-ui/core";

LayoutAdaptiveWrapper.propTypes = {
  children: PropTypes.node,
};

export default function LayoutAdaptiveWrapper({ children }) {
  return (
    <Box
      sx={{
        background: "linear-gradient(90deg, #f9f9f9 0%, #F2F2F2 25%, #f2f2f2 75%, #f9f9f9 100%)",
        minHeight: "100vh",
        height: "100%",
      }}
    >
      <Box
        sx={{
          zIndex: 1,
          margin: "0 auto",
          backgroundColor: "white",
          boxShadow: "rgba(0, 0, 0, 0.08) 0px 0px 32px 0px",
          maxWidth: { md: 600, lg: 1440 },
          height: "100%",
          minHeight: "inherit",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

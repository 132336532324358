import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { DevTool } from "@hookform/devtools";
import { Box, Link, Typography, Button, CircularProgress } from "@material-ui/core";
import { AUTH_TOKEN_STORAGE_KEY } from "@product-site-frontend/account/src/core/storage";
import FormFieldContainer from "@product-site-frontend/shared/components/FormFieldContainer";
import { maskedPhone } from "@product-site-frontend/shared/components/PhoneMaskInput";
import { SmartCaptcha } from "@yandex/smart-captcha";
import { useForm } from "react-hook-form";
import useFetch from "use-http";
import { useTimer } from "use-timer";

import { arenzaStorage } from "./FormApplication";

function TimeoutMessage({ children, initialTime }) {
  const { reset, start, time } = useTimer({
    initialTime,
    timerType: "DECREMENTAL",
    autostart: true,
    endTime: 0,
  });

  useEffect(() => {
    reset();
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTime]);

  return children({ time });
}

FormOtp.propTypes = {
  application: PropTypes.object,
  onSuccess: PropTypes.func,
  setMode: PropTypes.func,
  yandexCaptchaToken: PropTypes.string,
};

export default function FormOtp({ application, onSuccess, setMode, yandexCaptchaToken: propYandexCaptchaToken }) {
  const [yandexCaptchaToken, setYandexCaptchaToken] = useState(propYandexCaptchaToken || '');

  const {
    clearErrors,
    control,
    formState: {
      errors
    },
    handleSubmit,
    setError,
    watch
  } = useForm({
    mode: "onTouched",
  });

  const watchOtp = watch("otp");

  const { data, loading, post, response } = useFetch(`/short_apps/${application.id}`, {
    cachePolicy: "no-cache",
  });

  const [errorSendOtp, setErrorSendOtp] = useState('');

  useEffect(() => {
    // TODO: Не запрашивать, если только что произошел запрос

    post("send_otp", { smart_token: yandexCaptchaToken });
    setYandexCaptchaToken('');
  }, []);

  useEffect(() => {
    if (response?.data?.error === 'Invalid Captcha') {
      setErrorSendOtp('Ошибка при вводе капчи');
    }
  }, [response?.data]);

  const [loadingSubmitOtp, setLoadingSubmitOtp] = useState(false)

  async function onSubmit(data) {
    setLoadingSubmitOtp(true);

    post("/submit_otp", data).then(submitResponse => {
      if (response.ok) {
        arenzaStorage(AUTH_TOKEN_STORAGE_KEY, submitResponse.auth_token);
        post("/confirm").then(result => {
          if (response.ok) {
            onSuccess(submitResponse);
            // TODO: обработку ошибки
          } else {
            setError("FORM_ERROR", {
              type: "manual",
              message:
                result.message ||
                `Произошла неизвестная ошибка при подтверждении заявки. Попробуйте позднее. 
                ${JSON.stringify(result)}`,
            });
          }
        });

        setLoadingSubmitOtp(false);
      } else  {
        if (response.status === 429) {
          setError("otp", {
            type: "manual",
            message: "Слишком много неудачных попыток. Попробуйте позже.",
          });

          setLoadingSubmitOtp(false);
        } else if (response.status === 422) {
          setError("otp", {
            type: "manual",
            message: "Введенный код некорректный.",
          })

          setLoadingSubmitOtp(false);
        } else {
          setError("otp", {
            type: "manual",
            message: submitResponse.message || "Произошла неизвестная ошибка. Попробуйте позднее.",
          });

          setLoadingSubmitOtp(false);
        }
      }
    });
  }

  useEffect(() => {
    if (watchOtp?.length === 4) {
      handleSubmit(onSubmit)();
    }
  }, [watchOtp]);

  function handleResendOtpClick() {
    post("send_otp", { smart_token: yandexCaptchaToken });
    setYandexCaptchaToken('');
  }

  function handlePhoneChangeClick() {
    setMode("phone");
  }

  if (errorSendOtp && errorSendOtp.length) {
    return (
      <Typography sx={{ fontSize: '1.25rem', marginBottom: '20px' }}>
        {errorSendOtp}
      </Typography>
    )
  }

  return (
    <>
      <Typography mb={3} variant="h5">
        Нужно подтвердить ваш номер телефона
      </Typography>
      <Typography color="text.secondary" variant="subtitle2">
        Мы отправили на&nbsp;номер&nbsp;
        <Typography color="text.primary" component="span" noWrap variant="inherit">
          {maskedPhone.resolve(application.phone || "")}
        </Typography>{" "}
        смс с кодом
      </Typography>
      <FormFieldContainer
        InputLabelProps={{ htmlFor: "form-application-otp" }}
        control={control}
        // disabled={loading} NOTE: НЕЛЬЗЯ ИСПОЛЬЗОВАТЬ. СМ. Доки https://react-hook-form.com/api/useform/register. Вместо этого readOnly
        fieldType="text"
        id="form-application-otp"
        inputProps={{ maxLength: 4, inputMode: "numeric", autoFocus: true, readOnly: loading || loadingSubmitOtp }}
        label="Код из смс"
        name="otp"
        placeholder="0000"
        rules={{
          required: true,
          minLength: {
            value: 4,
            message: "Код из смс должен содержать 4 символа",
          },
        }}
      />

      {loading || loadingSubmitOtp ? (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          <TimeoutMessage initialTime={data?.timeout}>
            {({ time }) => {
              console.log("🚀 ~ file: FormOtp.jsx ~ line 100 ~ FormOtp ~ time", time);
              if (time > 0) {
                return (
                  <Typography variant="caption">
                    Повторный запрос кода возможен через <b>{time} сек</b>
                  </Typography>
                );
              }
              return (
                <>
                  <Button
                    disabled={!yandexCaptchaToken.length}
                    onClick={handleResendOtpClick}
                    sx={{
                      my: 2,
                      textTransform: 'initial',
                      fontWeight: 400,
                      color: 'rgb(24, 113, 228)',

                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                      '& .MuiTouchRipple-root': {
                        display: 'none',
                      },
                    }}
                    type="button"
                    underline="none"
                    variant="body2"
                  >
                    Отправить еще раз
                  </Button>

                  <Box sx={{ marginBottom: '24px', width: '100%' }}>
                    <SmartCaptcha onSuccess={setYandexCaptchaToken} sitekey="ysc1_ukeyoNivym2HND6EMGFlIbLdbdmtpMHoOQ0MZ2CX092b1063" />
                  </Box>
                </>
              );
            }}
          </TimeoutMessage>
        </Box>
      )}

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Link
          color="#1871e4"
          component="button"
          onClick={handlePhoneChangeClick}
          sx={{ my: 2 }}
          type="button"
          underline="none"
          variant="body2"
        >
          Изменить номер телефона
        </Link>
      </Box>
      <DevTool control={control} />
    </>
  );
}

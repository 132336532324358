import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

export default function LogoArenzaAuto(props) {
  return (
    <SvgIcon
      sx={{
        height: "100%",
        width: "auto",
      }}
      viewBox="0 0 246 38"
      {...props}
    >
      <path clipRule="evenodd" d="M160.052 3.88211L151.517 28.9978H158.717L160.437 22.9978H169.357L171.117 28.9978H178.317L169.781 3.88211C168.238 -0.658619 161.596 -0.65862 160.052 3.88211ZM167.717 17.3978H162.077L163.717 11.8378C164.063 10.6378 164.45 8.99784 164.877 6.91784H164.957L166.117 11.8378L167.717 17.3978Z"
            fill="#FF780F"
            fillRule="evenodd" />
      <path clipRule="evenodd" d="M222.25 18.7619C222.25 21.9352 223.37 24.5219 225.61 26.5219C227.85 28.4952 230.623 29.4819 233.93 29.4819C237.237 29.4819 240.01 28.4952 242.25 26.5219C244.49 24.5219 245.61 21.9352 245.61 18.7619C245.61 15.5619 244.477 12.9619 242.21 10.9619C239.97 8.93521 237.197 7.92188 233.89 7.92188C230.61 7.92188 227.85 8.93521 225.61 10.9619C223.37 12.9885 222.25 15.5885 222.25 18.7619ZM230.61 22.3619C229.703 21.4552 229.25 20.2552 229.25 18.7619C229.25 17.2419 229.703 16.0152 230.61 15.0819C231.517 14.1485 232.623 13.6819 233.93 13.6819C235.237 13.6819 236.343 14.1485 237.25 15.0819C238.157 16.0152 238.61 17.2419 238.61 18.7619C238.61 20.2552 238.157 21.4552 237.25 22.3619C236.343 23.2685 235.237 23.7219 233.93 23.7219C232.623 23.7219 231.517 23.2685 230.61 22.3619Z"
            fill="#FF780F"
            fillRule="evenodd" />
      <path d="M207.539 14.1984V28.9984H214.459V14.1984H220.739V8.39844H201.299V14.1984H207.539Z" fill="#FF780F" />
      <path clipRule="evenodd" d="M180.519 28.9984V8.39844H191.759C193.892 8.39844 195.586 8.8651 196.839 9.79844C198.119 10.7318 198.759 12.0651 198.759 13.7984C198.759 14.7051 198.546 15.5718 198.119 16.3984C197.692 17.1984 197.106 17.8251 196.359 18.2784V18.3184C197.452 18.6651 198.266 19.2651 198.799 20.1184C199.332 20.9451 199.599 21.8651 199.599 22.8784C199.599 24.9851 198.852 26.5318 197.359 27.5184C195.892 28.5051 194.026 28.9984 191.759 28.9984H180.519ZM187.439 16.5184H190.039C190.652 16.5184 191.106 16.3318 191.399 15.9584C191.719 15.5584 191.879 15.0651 191.879 14.4784C191.879 13.9451 191.719 13.5184 191.399 13.1984C191.079 12.8518 190.599 12.6784 189.959 12.6784H187.439V16.5184ZM187.439 24.6784H190.479C191.172 24.6784 191.706 24.4784 192.079 24.0784C192.479 23.6784 192.679 23.1984 192.679 22.6384C192.679 22.0518 192.492 21.5584 192.119 21.1584C191.746 20.7584 191.212 20.5584 190.519 20.5584H187.439V24.6784Z"
            fill="#FF780F"
            fillRule="evenodd" />
      <path clipRule="evenodd" d="M0.125977 28.9978L8.66181 3.88211C10.205 -0.658621 16.8469 -0.658618 18.3901 3.88211L26.926 28.9978H19.726L17.966 22.9978H9.04598L7.32598 28.9978H0.125977ZM10.686 17.3978H16.326L14.726 11.8378L13.566 6.91784H13.486C13.0593 8.99784 12.6726 10.6378 12.326 11.8378L10.686 17.3978Z"
            fill="#0D1D32"
            fillRule="evenodd" />
      <path clipRule="evenodd" d="M29.1284 8.40187V37.0019H36.0484V29.2819C36.0484 28.5885 36.0218 28.0019 35.9684 27.5219H36.0484C37.5684 28.8285 39.3151 29.4819 41.2884 29.4819C44.0618 29.4819 46.3551 28.4952 48.1684 26.5219C49.9818 24.5485 50.8884 21.9485 50.8884 18.7219C50.8884 15.4952 50.0351 12.8952 48.3284 10.9219C46.6484 8.92187 44.4084 7.92188 41.6084 7.92188C38.8618 7.92188 36.7818 8.86854 35.3684 10.7619H35.2884L35.3684 9.44187V8.40187H29.1284ZM36.8884 22.3219C36.1951 21.3352 35.8484 20.1752 35.8484 18.8419C35.8484 17.1619 36.2351 15.8952 37.0084 15.0419C37.7818 14.1885 38.7418 13.7619 39.8884 13.7619C41.0884 13.7619 42.0618 14.2285 42.8084 15.1619C43.5551 16.0952 43.9284 17.3219 43.9284 18.8419C43.9284 20.3619 43.5418 21.5619 42.7684 22.4419C41.9951 23.3219 41.0351 23.7619 39.8884 23.7619C38.6084 23.7619 37.6084 23.2819 36.8884 22.3219Z"
            fill="#0D1D32"
            fillRule="evenodd" />
      <path clipRule="evenodd" d="M53.2812 18.6819C53.2812 15.5885 54.2546 13.0285 56.2013 11.0019C58.1479 8.94854 60.7479 7.92188 64.0012 7.92188C66.9613 7.92188 69.2813 8.85521 70.9613 10.7219C72.6679 12.5619 73.5213 14.9485 73.5213 17.8819C73.5213 18.3619 73.4679 19.0819 73.3613 20.0419H60.3613C60.6012 21.2419 61.1613 22.1619 62.0413 22.8019C62.9479 23.4152 63.9879 23.7219 65.1613 23.7219C66.8679 23.7219 68.7613 23.0285 70.8412 21.6419L73.4012 26.4819C72.3079 27.4152 70.9613 28.1485 69.3613 28.6819C67.7879 29.2152 66.2013 29.4819 64.6013 29.4819C61.1079 29.4819 58.3479 28.4152 56.3213 26.2819C54.2946 24.1485 53.2812 21.6152 53.2812 18.6819ZM60.5213 16.0819H66.6812C66.6812 15.2019 66.4146 14.4685 65.8813 13.8819C65.3746 13.2952 64.6946 13.0019 63.8413 13.0019C62.0813 13.0019 60.9746 14.0285 60.5213 16.0819Z"
            fill="#0D1D32"
            fillRule="evenodd" />
      <path
        d="M100.26 26.4419L103.02 22.1219C103.687 22.7352 104.474 23.2552 105.38 23.6819C106.314 24.0819 107.18 24.2819 107.98 24.2819C109.634 24.2819 110.46 23.6819 110.46 22.4819C110.46 21.9219 110.3 21.4952 109.98 21.2019C109.687 20.9085 109.1 20.7619 108.22 20.7619H105.7V16.2019H107.78C109.167 16.2019 109.86 15.6685 109.86 14.6019C109.86 13.5085 109.14 12.9619 107.7 12.9619C106.207 12.9619 104.58 13.4819 102.82 14.5219L100.82 10.2419C103.06 8.69521 105.834 7.92188 109.14 7.92188C111.327 7.92188 113.14 8.44187 114.58 9.48187C116.02 10.5219 116.74 11.9085 116.74 13.6419C116.74 15.8019 115.887 17.3485 114.18 18.2819V18.3619C116.314 19.3485 117.38 20.8685 117.38 22.9219C117.38 25.0285 116.594 26.6552 115.02 27.8019C113.447 28.9219 111.394 29.4819 108.86 29.4819C107.287 29.4819 105.727 29.2285 104.18 28.7219C102.66 28.1885 101.354 27.4285 100.26 26.4419Z"
        fill="#0D1D32" />
      <path clipRule="evenodd" d="M119.406 23.2019C119.406 21.8952 119.766 20.7619 120.486 19.8019C121.206 18.8419 122.153 18.1085 123.326 17.6019C124.526 17.0952 125.766 16.7352 127.046 16.5219C128.326 16.2819 129.66 16.1619 131.046 16.1619H131.646V15.8019C131.646 14.2819 130.673 13.5219 128.726 13.5219C126.913 13.5219 125.02 14.1752 123.046 15.4819L120.646 10.6019C121.58 9.88188 122.86 9.25521 124.486 8.72187C126.14 8.18854 127.766 7.92188 129.366 7.92188C132.22 7.92188 134.46 8.66854 136.086 10.1619C137.74 11.6552 138.566 13.7085 138.566 16.3219V29.0019H132.246V27.7619L132.326 26.4819H132.246C131.58 27.4152 130.686 28.1485 129.566 28.6819C128.473 29.2152 127.326 29.4819 126.126 29.4819C124.153 29.4819 122.54 28.8819 121.286 27.6819C120.033 26.4819 119.406 24.9885 119.406 23.2019ZM126.246 22.5619C126.246 23.0685 126.42 23.4952 126.766 23.8419C127.113 24.1619 127.593 24.3219 128.206 24.3219C129.166 24.3219 129.993 23.9352 130.686 23.1619C131.38 22.3885 131.726 21.5619 131.726 20.6819V20.1219H131.086C129.806 20.1219 128.673 20.3219 127.686 20.7219C126.726 21.0952 126.246 21.7085 126.246 22.5619Z"
            fill="#0D1D32"
            fillRule="evenodd" />
      <path
        d="M77.1362 8.39844V28.9984H84.0562V21.4784H90.4162V28.9984H97.3362V8.39844H90.4162V15.6784H84.0562V8.39844H77.1362Z"
        fill="#0D1D32" />
      <path
        d="M145.5 29C147.433 29 149 27.433 149 25.5C149 23.567 147.433 22 145.5 22C143.567 22 142 23.567 142 25.5C142 27.433 143.567 29 145.5 29Z"
        fill="#0D1D32" />
    </SvgIcon>
  );
}

import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Box, Button, List, ListItem, Typography } from '@material-ui/core';
import {
  ApplicationContext,
  ExternalLink,
} from '@product-site-frontend/shared';

import arenzaSmile from '../assets/svg/arenza-smile.svg';
import { NAV_LINKS } from '../constants/nav-links';

DrawerNav.propTypes = {
  onDrawerClose: PropTypes.func,
};

export default function DrawerNav({ onDrawerClose }) {
  const { setApplicationOpen } = useContext(ApplicationContext);

  function handleApplicationButtonClick() {
    onDrawerClose();
    setApplicationOpen(true);
  }

  return (
    <Box
      color="white"
      display="flex"
      flexDirection="column"
      height={1}
      minWidth={290}
      sx={{
        backgroundImage: (theme) =>
          `linear-gradient(332deg, ${theme.palette.primary.light} 10%, ${theme.palette.primary.main} 60%)`,
      }}
      width="100vw"
    >
      <Box pt={3} />
      <List sx={{ overflow: 'auto', mb: '25px !important' }}>
        {NAV_LINKS.map(({ link, text }) => (
          <ListItem button component="a" href={link} key={link}>
            <Typography
              sx={{ fontWeight: 700, px: 3, py: 2 }}
              variant="subtitle2"
            >
              {text}
            </Typography>
          </ListItem>
        ))}

        <ListItem
          button
          component={ExternalLink}
          href={`${process.env.GATSBY_ROOT_URL}/faqs`}
        >
          <Typography
            sx={{ fontWeight: 700, px: 3, py: 2 }}
            variant="subtitle2"
          >
            Частые вопросы
          </Typography>
        </ListItem>

        <ListItem
          button
          component={ExternalLink}
          href={`${process.env.GATSBY_ROOT_URL}/blog`}
        >
          <Typography
            sx={{ fontWeight: 700, px: 3, py: 2 }}
            variant="subtitle2"
          >
            Блог
          </Typography>
        </ListItem>

        <ListItem
          button
          component={ExternalLink}
          href={`${process.env.GATSBY_DOCUMENTS_PUBLIC_URL}/tarrifs.pdf`}
        >
          <Typography
            sx={{ fontWeight: 700, px: 3, py: 2 }}
            variant="subtitle2"
          >
            Тарифы
          </Typography>
        </ListItem>

        <ListItem
          button
          component={ExternalLink}
          href={
            `${process.env.GATSBY_ROOT_URL}/lizing-auto`
          }
          title="Лизинг авто"
        >
          <Typography
            sx={{ fontWeight: 700, px: 3, py: 2 }}
            variant="subtitle2"
          >
            Arenza.Авто
          </Typography>
        </ListItem>

        <ListItem
          button
          component={ExternalLink}
          href={
            `${process.env.GATSBY_ROOT_URL}/lizing-meditsinskogo-oborudovaniya`
          }
          title="Лизинг медицинского оборудования"
        >
          <Typography
            sx={{ fontWeight: 700, px: 3, py: 2 }}
            variant="subtitle2"
          >
            Arenza.Медицина
          </Typography>
        </ListItem>

        <ListItem
          button
          component={ExternalLink}
          href={`${process.env.GATSBY_ROOT_URL}/shiny-v-lizing`}
          title="Лизинг шин"
        >
          <Typography
            sx={{ fontWeight: 700, px: 3, py: 2 }}
            variant="subtitle2"
          >
            Arenza.Шины
          </Typography>
        </ListItem>
      </List>
      <Box color="primary.main" mb={5} mt="auto" mx={5}>
        <Button
          color="inherit"
          fullWidth
          onClick={handleApplicationButtonClick}
          size="large"
          style={{ backgroundColor: 'white' }}
          variant="contained"
        >
          Подать заявку
        </Button>
      </Box>
      <Box
        alt="Arenza"
        bottom={128}
        component="img"
        position="absolute"
        right={32}
        src={arenzaSmile}
        width={80}
      />
    </Box>
  );
}
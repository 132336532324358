import PropTypes from "prop-types";
import React from "react";

import { Box, Drawer } from "@material-ui/core";

import AppBar from "../components/AppBar";
import DrawerNav from "../components/DrawerNav";

LayoutNavigation.propTypes = {
  children: PropTypes.node,
};
export default function LayoutNavigation({ children }) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  function closeApplicationDrawer() {
    setDrawerOpen(false);
  }

  function toggleDrawer() {
    setDrawerOpen(!drawerOpen);
  }

  return (
    <>
      <AppBar drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
      <Box
        sx={{
          minHeight: theme => theme.mixins.toolbar /* Компенсация высоты appbar */,
        }}
      />
      {children}
      <Drawer
        BackdropComponent={() => null}
        PaperProps={{
          sx: { top: 56, height: "calc(100% - 56px)" },
        }}
        anchor="left"
        onClose={closeApplicationDrawer}
        open={drawerOpen}
        sx={{ top: "56px !important", display: { xs: "block", md: "none" } }}
      >
        <DrawerNav onDrawerClose={closeApplicationDrawer} />
      </Drawer>
    </>
  );
}

import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

export default function PulsLogo(props) {
  return (
    <SvgIcon
      sx={{
        height: "100%",
        width: "100%",
      }}
      viewBox="0 0 38 38"
      {...props}
    >
      <path
        d="M29.5662 17.9583C30.0572 17.9611 30.5481 17.9639 31.0387 17.9639C32.2987 17.9639 33.5588 17.9639 34.8171 17.9622C37.2032 17.9622 37.2582 17.9502 36.727 15.622C35.1059 8.51733 30.8599 3.6583 23.8204 1.73565C16.7345 -0.199024 10.5201 1.70816 5.50739 7.15137C2.92367 9.95545 1.55703 13.3403 1.08085 17.0979C1.07526 17.1428 1.06846 17.1881 1.06169 17.2331C1.00393 17.6175 0.948452 17.9867 1.66704 17.9759C4.61919 17.9341 7.57296 17.9509 10.5268 17.9677L10.7728 17.9691C11.1888 17.9708 11.3831 17.8316 11.5103 17.4467C11.7423 16.7434 11.9901 16.0444 12.238 15.3455C12.3722 14.9671 12.5064 14.5887 12.638 14.2097C12.901 13.4554 13.403 12.9726 14.2178 13.0104C15.0223 13.0482 15.483 13.5447 15.6962 14.3317C16.1208 15.9004 16.5798 17.4588 17.0491 19.0137C17.0665 19.0714 17.0769 19.1398 17.0876 19.2103C17.1221 19.4379 17.16 19.6875 17.4513 19.6718C17.6972 19.659 17.7046 19.4393 17.7115 19.2321C17.7139 19.1612 17.7162 19.0918 17.7281 19.0326C18.0407 17.4909 18.3498 15.9484 18.6589 14.4058C19.0008 12.6993 19.3428 10.9927 19.6895 9.28708C19.9061 8.22524 20.4012 7.72696 21.1988 7.69088C22.0068 7.6548 22.5741 8.17885 22.8526 9.24069C22.9647 9.66934 23.077 10.0979 23.1893 10.5264C23.7722 12.7517 24.355 14.9759 24.9171 17.2062C25.0547 17.7526 25.2575 18 25.885 17.9811C27.1105 17.9443 28.3386 17.9513 29.5662 17.9583Z"
        fill="white"
      />
      <path
        d="M14.5117 21.4966C14.3347 20.8998 14.1531 20.2878 13.9651 19.6544C13.4631 20.9998 12.5486 21.0616 11.5533 21.053C10.023 21.0426 8.49318 21.0434 6.96334 21.0443C5.22956 21.0453 3.49582 21.0462 1.76159 21.0307C1.15649 21.0256 0.996616 21.1991 1.00005 21.797C1.02412 26.6337 1.02068 31.4687 1.00177 36.3054C1.00005 36.8724 1.15649 37.039 1.73237 36.9927C4.127 36.8002 6.08843 35.7126 7.74903 34.0631C8.17879 33.637 8.39367 33.5408 8.9197 33.8982C11.4948 35.6507 14.3313 36.6335 17.4582 36.9205C26.815 37.7813 35.3964 31.1165 36.837 21.8314C36.947 21.1183 36.7562 21.0307 36.1305 21.0341C32.4162 21.0553 28.7004 21.0487 24.9844 21.0421L24.3636 21.041C23.1001 21.0376 22.6755 20.6922 22.3523 19.4964C22.3022 19.3118 22.2594 19.1249 22.2165 18.9379C22.0945 18.406 21.9723 17.8734 21.6836 17.3916C21.6637 17.3582 21.6567 17.3153 21.6499 17.2728C21.6349 17.18 21.6203 17.0893 21.4704 17.1047C21.2762 17.1253 21.1731 17.2627 21.1438 17.4466C21.1166 17.6145 21.0928 17.7829 21.069 17.9513C21.0297 18.2291 20.9904 18.5067 20.9358 18.7816C20.6599 20.1682 20.381 21.5543 20.1021 22.9405C19.8232 24.3266 19.5443 25.7128 19.2684 27.0993C19.1154 27.8639 18.6701 28.3399 17.9 28.4223C17.1264 28.5065 16.5712 28.1388 16.2996 27.4052C16.1422 26.9812 16.0125 26.5447 15.8832 26.1094C15.8685 26.0597 15.8537 26.0099 15.8389 25.9602C15.405 24.5079 14.9739 23.0546 14.5117 21.4966Z"
        fill="white"
      />
    </SvgIcon>
  );
}

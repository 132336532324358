import PropTypes from "prop-types";
import React from "react";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@material-ui/core";
import _noop from "lodash.noop";

FormFieldCheckboxGroupWrapper.propTypes = {
  field: PropTypes.object,
  fieldState: PropTypes.object,
  formState: PropTypes.object,
  helperText: PropTypes.string,
  label: PropTypes.node,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.array,
};
export default function FormFieldCheckboxGroupWrapper({
  field: { onChange: _onChange, ref, value },
  fieldState: { error, invalid, isTouched },
  formState,
  helperText,
  label,
  onBlur = _noop,
  onChange = _noop,
  options = [],
}) {
  // const showError = !!(isTouched && invalid);
  const showError = !!invalid;

  function handleChange(event) {
    // onChange(event);

    if (value.includes(event.target.value)) {
      _onChange(value.filter(v => v !== event.target.value));
    } else {
      _onChange([...value, event.target.value]);
    }
  }

  return (
    <FormControl component="fieldset" error={showError}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <FormGroup onBlur={onBlur} onChange={handleChange} value={value}>
        {options.map(opt => (
          <FormControlLabel
            checked={value.includes(opt.value)}
            control={<Checkbox />}
            inputRef={ref}
            key={opt.label}
            label={opt.label}
            value={opt.value}
          />
        ))}
      </FormGroup>
      <FormHelperText variant="standard">
        {(showError && error?.message) || helperText}
      </FormHelperText>
    </FormControl>
  );
}

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from "react";

import { TextField, Box, ClickAwayListener } from "@material-ui/core";
import { Popper } from "@mui/material";
import { ru } from "date-fns/locale";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import { DayPicker } from 'react-day-picker';
import { IMaskInput } from 'react-imask';

import iconPiker from "./img/piker.svg";

// eslint-disable-next-line react/display-name
const MaskInput = React.forwardRef(({ onChange: onChangeProps, value: valueInput }, ref) => {
  const [value, setValue] = useState(valueInput || '');

  const onChange = val => {
    if (onChangeProps && typeof onChangeProps === 'function') {
      onChangeProps(val)
    }

    setValue(val)
  }

  useEffect(() => {
    setValue(valueInput)
  }, [valueInput])

  return (
    <IMaskInput
      blocks={
        {
          from: {
            mask: Date,
          },
          to: {
            mask: Date
          },
        }
      }
      inputRef={ref}
      lazy={false}
      mask={'from - to'}
      max={new Date(2025, 0, 1)}
      min={new Date(2000, 0, 1)}
      onAccept={(value) => onChange(value)}
      placeholder='13.12.3123 - 12.12.3123'
      style={{
        background: 'transparent',
        border: 'none',
        outline: 'none',
        width: '100%',
      }}
      value={value}
    />
  );
});

MaskInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

const DateRageMaskInput = ({ onChange: onChangeProps }) => {
  const styles = useMemo(() => ({
    wrapper: {
      minWidth: '120px',
      width: '100%',
      margin: '4px',
      position: 'relative',
    },
    field: {
      background: '#f5f6f7',
      border: '1px solid #f5f6f7',
      borderRadius: '8px',
      margin: '0',

      '&.Mui-focused': {
        border: '1px solid #ee8035',
      },
      '& fieldset': {
        display: 'none',
      },
      '&:hover': {
        background: '#ecedef',
        border: '1px solid #ecedef',
      }
    },
    dateInput: {
      padding: '14.5px 12px',
    },
    wrapperDP: {
      zIndex: 9999,
      background: '#fff',
      boxShadow: '0px 20px 64px 0px rgba(13, 29, 50, 0.10)',
      borderRadius: '32px',
    }
  }),[]);

  const [selectedDates, setSelectedDates] = useState({
    from: undefined,
    to: undefined
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const onToggleDP = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  const handleClickAway = e => {
    if (e.target.classList.contains("iconDayPicker")) return false;

    setAnchorEl(null)
  }

  const [valueInputMask, setValueInputMask] = useState('');

  const setDateForDP = value => {
    let cloneValue = cloneDeep(value);
    let arrValue = cloneValue.split(' - ');

    const cloneSelectedDates = cloneDeep(selectedDates);

    const converDate = date => `${date.split('.')[1]}.${date.split('.')[0]}.${date.split('.')[2]}`;

    if (arrValue[0].replace(/[._]/g, '')?.length === 8) {
      cloneSelectedDates.from = dayjs(converDate(arrValue[0]))?.$d
    }

    if (arrValue[1].replace(/[._]/g, '')?.length === 8) {
      cloneSelectedDates.to = dayjs(converDate(arrValue[1]))?.$d
    }

    setSelectedDates(cloneSelectedDates)
  }

  const setDateForInputMask = date => {
    let value = '';
    if (date?.from) {
      value = dayjs(date?.from).format('DD.MM.YYYY');
    }

    if (date?.to) {
      value += ' - ' + dayjs(date?.to).format('DD.MM.YYYY')
    }

    setValueInputMask(value);
  }

  useEffect(() => {
    setDateForInputMask(selectedDates)

    if (onChangeProps && typeof onChangeProps === 'function' && selectedDates?.from && selectedDates?.to) {
      onChangeProps(selectedDates)
    }
  }, [selectedDates])

  const onChange = value => {
    setDateForDP(value);
  }

  return (
    <Box sx={ styles.wrapper }>
      <TextField
        InputProps={{
          inputComponent: MaskInput,
          endAdornment: (
            <Box
              className={'iconDayPicker'}
              component="img"
              height={24}
              onClick={onToggleDP}
              src={iconPiker}
              sx={{ cursor: 'pointer', marginLeft: '8px' }}
              width={24}
            />
          )
        }}
        fullWidth
        onChange={onChange}
        saveEvent="none"
        sx={{ ...styles.field, ...styles.dateInput }}
        value={valueInputMask}
      />

      <ClickAwayListener onClickAway={handleClickAway}>
        <Popper
          anchorEl={anchorEl}
          id={id}
          open={open}
          // ref={rootEl}
          sx={ styles.wrapperDP }
        >
          <DayPicker
            locale={ru}
            mode="range"
            onSelect={setSelectedDates}
            selected={selectedDates}
          />
        </Popper>
      </ClickAwayListener>
    </Box>
  )
}

DateRageMaskInput.propTypes = {
  onChange: PropTypes.func,
};

export default DateRageMaskInput;

import PropTypes from "prop-types";
import React from "react";

import FieldAutocompleteLegalEntity from "./FieldAutocompleteLegalEntity";

FormFieldAutocompleteLegalEntityWrapper.propTypes = {
  control: PropTypes.object,
  field: PropTypes.any,
  fieldState: PropTypes.any,
  formState: PropTypes.any,
  helperText: PropTypes.any,
  label: PropTypes.node,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.array,
};
export default function FormFieldAutocompleteLegalEntityWrapper({
  options = [],
  field: { onBlur: _onBlur, onChange: _onChange, ref, value = null },
  fieldState: { error, invalid },
  formState,
  helperText,
  control,
  label,
  onBlur,
  onChange,
  ...props
}) {
  const showError = !!invalid;

  function handleChange(option) {
    onChange(option?.data.inn || null);
  }

  return (
    <FieldAutocompleteLegalEntity
      { ...props }
      defaultValue={value}
      disabled={props.disabled || false}
      error={showError}
      helperText={(showError && error?.message) || helperText}
      inputRef={ref}
      label={label}
      onBlur={onBlur}
      onChange={handleChange}
    />
  );
}

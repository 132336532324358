export const NAV_LINKS = [
  {
    text: "Акции 🔥",
    link: `${process.env.GATSBY_ROOT_URL}/promotions`,
  }, {
    text: "Arenza.renew",
    link: process.env.GATSBY_SHOP_URL,
  },
  {
    text: "Лизинг оборудования",
    link: process.env.GATSBY_ROOT_URL,
  },
  {
    text: "Партнерам",
    link: `${process.env.GATSBY_ROOT_URL}/partners`,
  },
  {
    text: "Выбрать поставщика",
    link: `${process.env.GATSBY_ROOT_URL}/marketplace`,
  },
  {
    text: "Инвесторам",
    link: `${process.env.GATSBY_ROOT_URL}/about#investors`,
  },
  {
    text: "О нас",
    link: `${process.env.GATSBY_ROOT_URL}/about`,
  },
];

import PropTypes from "prop-types";
import React from "react";

import { InputAdornment, TextField } from "@material-ui/core";
import { useController } from "react-hook-form";

import IconValidField from "./IconValidField";

FormTextField.propTypes = {
  InputProps: PropTypes.object,
  control: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.node,
  inputProps: PropTypes.object,
  name: PropTypes.string,
  onSubmit: PropTypes.func,
};
export default function FormTextField({
  InputProps,
  control,
  helperText,
  inputProps,
  name,
  rules,
  ...props
}) {
  const Controller = useController({
    name,
    control,
    defaultValue: "",
    rules,
  });

  const {
    field: { ref, ...fieldProps },
    fieldState: { error, invalid, isDirty, isTouched },
  } = Controller;

  const showError = !!(isTouched && isDirty && invalid);
  // function isError(field) {
  //   return !!formState.errors[field] && (formState.touchedFields[field] || formState.isSubmitted);
  // }
  // function getHelperText(field) {
  //   return (
  //     ((formState.touchedFields[field] || formState.isSubmitted) &&
  //       formState.errors[field]?.message) ||
  //     " "
  //   );
  // }

  return (
    <TextField
      InputProps={{
        ...(fieldProps.value &&
          !invalid &&
          isTouched && {
            endAdornment: (
              <InputAdornment>
                <IconValidField />
              </InputAdornment>
            ),
          }),
        ...InputProps,
      }}
      error={showError}
      fullWidth
      helperText={(showError && error?.message) || helperText || " "}
      {...props}
      {...fieldProps}
      inputRef={ref}
    />
  );
}

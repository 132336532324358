/**
 * Как получить финансирование
 */
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import {
  SectionContainer,
  Step,
  ApplicationContext,
} from '@product-site-frontend/shared';
import { GatsbyImage } from 'gatsby-plugin-image';

HowToGetFinancing.propTypes = {
  content: PropTypes.shape({
    bgDesktop: PropTypes.object,
    title: PropTypes.string,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
  }),
};

export default function HowToGetFinancing({ content }) {
  const { setAmount, setApplicationOpen } = useContext(ApplicationContext);

  const handleApplicationClick = useCallback(() => {
    setAmount('');
    setApplicationOpen(true);
  }, [setApplicationOpen, setAmount]);

  const { bgDesktop, steps, title } = content;

  return (
    <SectionContainer id={"howToGetFinancing"} title={title}>
      <Box alignItems="center" display="flex" mb={8}>
        <Box sx={{ display: { xs: "none", lg: "flex" } }} width={0.4}>
          <GatsbyImage alt="" image={bgDesktop.localFile.childImageSharp.gatsbyImageData} />
        </Box>
        <Box width={{ xs: 1, lg: 0.5 }}>
          {steps.map(({ description, title }, index) => (
            <Box display="flex" key={index} mt={index ? 7 : 0}>
              <Step mr={{ xs: 3, sm: 4 }}>{index + 1}</Step>
              <Box>
                <Typography component="div" sx={{ mb: 3, mt: 0, textAlign: { lg: "left" } }} variant="h3">
                  {title}
                </Typography>
                <Typography variant="body1">{description}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box maxWidth={375} mx="auto" width={{ xs: 1, lg: "auto" }}>
        <Button
          color="primary"
          fullWidth
          onClick={handleApplicationClick}
          size="large"
          variant="contained"
        >
          Подать заявку
        </Button>
      </Box>
    </SectionContainer>
  );
}

import PropTypes from "prop-types";
import React from "react";

// import Loadable from "@loadable/component";
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import _noop from "lodash.noop";

// const ApplicationForm = Loadable(() => import("./ApplicationForm.old.jsx"));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

DialogApplicationCreate.propTypes = {
  content: PropTypes.node,
  keepMounted: PropTypes.bool,
  maxWidth: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.node,
};
export default function DialogApplicationCreate({
  maxWidth = "md",
  onClose = _noop,
  open = true,
  title,
  content,
  keepMounted = true,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

  return (
    <Dialog
      TransitionComponent={fullScreen ? Transition : undefined}
      fullScreen={fullScreen}
      fullWidth
      keepMounted={keepMounted}
      maxWidth={maxWidth}
      onClose={onClose}
      open={open}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '32px',
        }
      }}
    >
      <DialogTitle component="div" sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography component="div" noWrap sx={{ fontWeight: 600 }} variant="inherit">
          {title}
        </Typography>
        <IconButton aria-label="close" color="inherit" edge="end" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={!!title}>{content}</DialogContent>
    </Dialog>
  );
}

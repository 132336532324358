import PropTypes from "prop-types";
import React from "react";

import { Box, Typography } from "@material-ui/core";

Step.propTypes = {
  children: PropTypes.node,
};

export default function Step({ children, ...props }) {
  return (
    <Box
      border="3px solid"
      borderRadius="50%"
      color="primary.main"
      flexShrink={0}
      height={{ xs: 48, md: 64 }}
      width={{ xs: 48, md: 64 }}
      {...props}
    >
      <Typography
        color="text.primary"
        sx={{
          fontSize: { xs: "24px", md: "32px" },
          fontWeight: 900,
          lineHeight: { xs: "44px", md: "62px" },
          textAlign: "center",
        }}
      >
        {children}
      </Typography>
    </Box>
  );
}

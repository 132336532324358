import PropTypes from "prop-types";
import React from "react";

import { createTheme, responsiveFontSizes, ThemeProvider } from "@material-ui/core";
import { theme } from "@product-site-frontend/shared";
import { green } from "@product-site-frontend/shared/theme/colors";

let medicineTheme = createTheme(theme, {
  palette: {
    primary: {
      main: green,
      light: "#7ab07a",
      dark: "#006900",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          "& .highlight.primary": {
            color: green,
          },
        },
      },
    },
  },
});
medicineTheme = responsiveFontSizes(medicineTheme, { factor: 6 });

LayoutMedicineThemeProvider.propTypes = {
  children: PropTypes.node,
};
export default function LayoutMedicineThemeProvider({ children }) {
  return <ThemeProvider theme={medicineTheme}>{children}</ThemeProvider>;
}

import PropTypes from "prop-types";
import React from "react";

import { Typography } from "@material-ui/core";

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
};

export default function ExternalLink({ href, ...props }) {
  return (
    <Typography
      component="a"
      href={href}
      rel="noopener noreferrer"
      target="_blank"
      variant="link"
      {...props}
    />
  );
}

import React from "react";

import { Footer } from "@product-site-frontend/shared";
import { graphql, useStaticQuery } from "gatsby";

const FOOTER_CONTENT = graphql`
  query FooterContent {
    content: strapiFooter {
      aboutCompany
      companyAddress
      companyDetails
      companyEmail
      eSignatureAgreementLink {
        text
        url
      }
      privacyPolicyLink {
        text
        url
      }
      social {
        title
        url
        text
      }
    }
  }
`;

export default function FooterContainer() {
  const { content } = useStaticQuery(FOOTER_CONTENT);

  return <Footer content={content} />;
}

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Autocomplete } from '@material-ui/core';
import debounce from 'lodash/debounce';
import mergeRefs from 'react-merge-refs';
import useFetch from 'use-http';

import CustomTextField from './FieldTextExtended';

var token = '640f03ef7ad1b62d099385d9b724257b51376ee7';

FormFieldAutocompleteNameWrapper.propTypes = {
  field: PropTypes.object,
  fieldState: PropTypes.object,
  formState: PropTypes.object,
  helperText: PropTypes.node,
};

export default function FormFieldAutocompleteNameWrapper({
  field: { name, onChange: _onChange, ref, value },
  fieldState: { error, invalid },
  formState,
  helperText,
  ...props
}) {
  const searchInput = useRef(null);

  const showError = useMemo(() => !!invalid, [invalid]);

  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const { post, response } = useFetch(
    'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio',
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Token ' + token,
      },
      cachePolicy: 'no-cache',
      retries: 0,
    },
  );

  const fetch = useMemo(
    () =>
      debounce((body, callback) => {
        post(body).then((result) => {
          if (response.ok) {
            callback(result.suggestions);
          } else {
            Rollbar.error('Ошибка при запросе suggestions');
          }
        });
      }, 400),
    [post, response.ok],
  );

  useEffect(() => {
    if (document.activeElement === searchInput.current) {
      fetch({ count: 5, query: inputValue }, (results) => {
        let newOptions = [];

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      });
    }
  }, [inputValue, fetch]);

  return (
    <Autocomplete
      autoComplete
      autoHighlight
      disableClearable
      disabled={props.disabled || false}
      freeSolo
      getOptionLabel={(option) => {
        return typeof option === 'string' ? option : option.value;
      }}
      includeInputInList
      inputValue={inputValue}
      onChange={(event, newOption) => _onChange(newOption.value)}
      onInputChange={(event, value) => setInputValue(value)}
      options={options}
      renderInput={(
        { InputProps: { className, endAdornment, ...restInputProps },
          ...params
        }) => (
        <CustomTextField
          {...props}
          {...params}
          InputProps={restInputProps}
          error={showError}
          helperText={(showError && error?.message) || helperText}
          inputRef={mergeRefs([searchInput, ref])}
          name={name}
          showValidIcon={Boolean(value && !invalid)}
        />
      )}
      value={value}
    />
  );
}
import * as yup from "yup";

export const FIO_VALIDATION = yup
  .string()
  .trim()
  .max(256, "Укажите фамилию, имя и отчество через пробел")
  // https://sudact.ru/law/pismo-fns-rossii-ot-13092018-n-bs-4-2117872/prilozhenie/prilozhenie_1/prilozhenie_2/prilozhenie-n-1/2_1/2.6/
  .matches(
    /^[\\'\\.\-IV\u0401\u0451\u0410-\u044f]{2,} [\s\\'\\.\-IV\u0401\u0451\u0410-\u044f]{2,}$/gi,
    {
      message: "Укажите фамилию, имя и отчество через пробел",
      excludeEmptyString: true,
    },
  );

export const PHONE_VALIDATION = yup
  .string()
  .nullable()
  // .required("Укажите номер телефона")
  .matches(/(^\+7\s\([0-9]{3}\)\s[0-9]{3}-[0-9]{2}-[0-9]{2}$)/, {
    message: "Номер телефона должен состоять из 10 цифр",
    excludeEmptyString: true,
  });

export const EMAIL_VALIDATION = yup
  .string()
  .trim()
  .email("Проверьте адрес электронной почты");


export const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
import PropTypes from "prop-types";
import React from "react";

import mergeRefs from "react-merge-refs";

import FieldTextExtended from "./FieldTextExtended";

FormFieldTextWrapper.propTypes = {
  control: PropTypes.object,
  field: PropTypes.object,
  fieldState: PropTypes.object,
  formState: PropTypes.object,
  helperText: PropTypes.node,
};

export default function FormFieldTextWrapper({
  control,
  field: { name, ref, value },
  fieldState: { error, invalid },
  formState,
  helperText,
  ...props
}) {
  // const showError = !!(isTouched && isDirty && invalid); // не подходит для селекта
  // const showError = !!(isTouched && invalid);
  const inputRef = React.useRef(null);
  const showError = !!invalid;

  return (
    <FieldTextExtended
      {...props}
      error={showError}
      fullWidth
      helperText={(showError && error?.message) || helperText}
      inputRef={mergeRefs([inputRef, ref])}
      name={name}
      // showValidIcon={Boolean(value && !invalid && document.activeElement !== inputRef.current)}
      showValidIcon={Boolean(value && !invalid)}
      value={value}
    />
  );
}

import { orange } from "./colors";
import { palette } from "./palette";

export const components = {
  MuiAppBar: {
    styleOverrides: {
      colorDefault: {
        backgroundColor: "white",
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        "& .highlight.primary": {
          color: orange,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontWeight: 500,
        "legend&, legend&.Mui-focused": {
          fontWeight: 600,
          color: "#373737",
          marginBottom: 16,
        },
        "&$error": {
          color: "inherit",
        },
      },
    },
  },
  MuiFormControl: {
    defaultProps: {
      margin: "normal",
    },
    styleOverrides: {
      marginNormal: {
        // marginTop: 4,
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontSize: 16,
        fontWeight: 500,
        userSelect: "none",
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      color: "primary",
    },
  },
  MuiStepIcon: {
    styleOverrides: {
      root: {
        "&.MuiStepIcon-completed": {
          color: "#00c227",
        },
        "&.Mui-active": {
          color: "#00c227",
        },
      },
    },
  },

  MuiRadio: {
    defaultProps: {
      color: "primary",
    },
    styleOverrides: {
      root: {
        "&.Mui-checked + .MuiFormControlLabel-label": {
          color: "#2F2F2F",
        },
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      // helperText: " ",
      margin: "normal",
    },
  },
  MuiFilledInput: {
    defaultProps: {
      disableUnderline: true,
    },
    styleOverrides: {
      root: {
        color: "rgba(0,0,0,0.87)",
        fontWeight: 500,
        backgroundColor: "#eef2f7",
        // backgroundColor: "#fcfcfb",
        // ---
        border: "1px solid #e2e2e1",
        overflow: "hidden",
        borderRadius: 4,
        //     transition: theme.transitions.create([
        //       'border-color',
        //       'background-color',
        //       'box-shadow',
        //     ]),
        // ---

        // "&:hover": {
        //   // backgroundColor: "#e4eaf2",
        //   backgroundColor: "transparent",
        // },
        // "&:hover:not(.Mui-disabled)": {
        //   backgroundColor: "#e4eaf2",
        //   // backgroundColor: "transparent",
        // },

        "&.Mui-focused": {
          backgroundColor: "white",
          // backgroundColor: "transparent",
        },
        "&.Mui-error": {
          borderColor: palette.error.main,
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        color: "rgba(0,0,0,0.87)",
        fontWeight: 500,
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      ":root": {
        "--swiper-theme-color": `#f6863a !important`,
        "--swiper-pagination-color": `var(--swiper-theme-color)`,
        "--swiper-navigation-color": `var(--swiper-theme-color)`,
      },
      // https://hankchizljaw.com/wrote/a-modern-css-reset/
      /* Указываем box sizing */
      "*, *::before, *::after": {
        boxSizing: "border-box",
      },

      /* Выставляем основные настройки по-умолчанию для body */
      body: {
        minHeight: "100vh",
        scrollBehavior: "smooth",
        textRendering: "optimizeSpeed",
        lineHeight: 1.5,
      },

      /* Убираем внешние отступы */
      "body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd": {
        margin: 0,
      },

      /* Убираем внутренние отступы */
      /* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
      "ul[class], ol[class]": {
        padding: 0,
        listStyle: "none",
      },

      /* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
      "a:not([class])": {
        textDecorationSkipInk: "auto",
      },

      /* Упрощаем работу с изображениями */
      img: {
        maxWidth: "100%",
        display: "block",
      },

      /* Указываем понятную периодичность в потоке данных у article*/
      "article > * + *": {
        marginTop: "1em",
      },

      // /* Наследуем шрифты для инпутов и кнопок */
      "input, button, textarea, select": {
        font: "inherit",
      },

      /* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
      "@media (prefers-reduced-motion: reduce)": {
        "*": {
          animationDuration: "0.01ms !important",
          animationIterationCount: "1 !important",
          transitionDuration: "0.01ms !important",
          scrollBehavior: "auto !important",
        },
      },

      a: {
        color: "unset",
        textDecoration: "unset",
      },
    },
  },
};

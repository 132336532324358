import PropTypes from "prop-types";
import React from "react";

import { Container } from "@material-ui/core";
import MuiAppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { Link } from "gatsby";

import ArenzaFullLogo from "./ArenzaFullLogo";


function ElevationScroll(props) {
  const { children } = props;
  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });

  return React.cloneElement(children, {
    scrollTrigger,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

Header.propTypes = {
  children: PropTypes.node,
  hideLogo: PropTypes.bool,
  onDrawerOpen: PropTypes.func,
  scrollTrigger: PropTypes.bool,
};

function Header({ children, hideLogo, scrollTrigger }) {
  const appBarProps = scrollTrigger
    ? {
      elevation: 4,
      color: "default",
    }
    : {
      elevation: 0,
      color: "transparent",
    };

  return (
    <MuiAppBar position="fixed" style={{ transition: "background-color 0.3s" }} {...appBarProps}>
      <Box
        sx={{
          maxWidth: [1, 1, 600, 1440],
          mx: "auto",
          width: 1,
        }}
      >
        <Container maxWidth="lg" style={{ backgroundColor: "white" }}>
          <Toolbar disableGutters>
            {!hideLogo && (
              <Box
                component={Link}
                sx={{ width: { xs: 108, sm: 134 }, flexShrink: 0, height: { xs: 24, sm: 40 } }}
                to={process.env.GATSBY_ROOT_URL}
              >
                <ArenzaFullLogo color="primary" />
              </Box>
            )}
            {children}
          </Toolbar>
        </Container>
      </Box>
    </MuiAppBar>
  );
}

export default function ElevationAppBar(props) {
  return (
    <ElevationScroll>
      <Header {...props} />
    </ElevationScroll>
  );
}

import PropTypes from "prop-types";
import React from "react";

import IMask from "imask";
import { IMaskInput } from "react-imask";

const defaultMask = [
  { mask: "" },
  {
    mask: "num руб",
    lazy: false,
    blocks: {
      num: {
        mask: Number,
        signed: false,
        thousandsSeparator: " ",
      },
    },
  },
];

export const maskedAmount = IMask.createMask({
  mask: defaultMask,
});

// eslint-disable-next-line react/display-name
const AmountMaskInput = React.forwardRef(({ mask = defaultMask, onChange, ...props }, ref) => {
  return (
    <IMaskInput
      {...props}
      inputRef={ref}
      mask={mask}
      onAccept={value => onChange(value.replace(/[^0-9]/g, ""))}
    />
  );
});

AmountMaskInput.propTypes = {
  mask: PropTypes.any,
  onChange: PropTypes.func,
};

export default AmountMaskInput;

import PropTypes from "prop-types";
import React, { useState } from "react";

import Loadable from "@loadable/component";
import { Box, Dialog, IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import ButtonVK from "./ButtonVK";

HelpVideo.propTypes = {
  videoText: PropTypes.node,
  videoUrl: PropTypes.string,
};

export default function HelpVideo({ videoText, videoUrl }) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Dialog
        PaperProps={{ style: { overflow: "hidden", background: "rgba(0,0,0, 0.4)" } }}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        open={open}
      >
        <Box color="white" display="flex" justifyContent="flex-end">
          <IconButton color="inherit" onClick={handleClose}>
            <CloseIcon color="inherit" />
          </IconButton>

        </Box>
        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
        <iframe allow="autoplay; encrypted-media; fullscreen; picture-in-picture" allowFullScreen="1" frameBorder="0" height="340" src={videoUrl} width="100%"></iframe>
      </Dialog>
      <ButtonVK label={videoText} onClick={handleOpen} />
    </>
  );
}

import PropTypes from "prop-types";
import React from "react";

import { Typography, Container, Box } from "@material-ui/core";

const sx = {
  container: {
    py: { xs: 5, lg: 8 },
  },
  heading: {
    mb: {
      xs: 4,
      md: 5,
      lg: 6,
    },
    textAlign: { lg: "center" },
    display: 'flex',
    justifyContent: { lg: "center" },
    alignItems: 'center',
    gap: '8px',
    flexWrap: 'wrap'
  },
  new: {
    display: 'inline-block',
    padding: '4px 8px',
    borderRadius: '4px',
    color: '#fff',
    background: '#ff6600',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: '400',
    position: 'relative',
    top: '2px'
  }
};

export default function SectionContainer({ children, title, titleNew, ...props }) {
  return (
    <Container component="section" maxWidth="lg" sx={sx.container} {...props}>
      {title && (
        <Typography sx={sx.heading} variant="h2">
          {title}
          {titleNew && (
            <Box component="span" sx={sx.new}>NEW</Box>
          )}
        </Typography>
      )}
      {children}
    </Container>
  );
}

SectionContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  titleNew: PropTypes.bool
};

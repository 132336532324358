import { createTheme } from "@material-ui/core/styles";

import { components } from "./components";
import { palette } from "./palette";
import { shadows } from "./shadows";
import { typography } from "./typography";

const breakpoints = {
  values: {
    xs: 0,
    sm: 451,
    md: 601,
    lg: 1281,
  },
};

const spacing = [0, 4, 8, 16, 24, 32, 40, 48, 56, 64];

const coreTheme = createTheme({
  shadows,
  breakpoints,
  palette,
  typography,
  spacing,
  components,
});

let mainTheme = createTheme(coreTheme, {
  mixins: {
    toolbar: {
      [coreTheme.breakpoints.up("sm")]: {
        minHeight: 56,
      },
      [`${coreTheme.breakpoints.up("lg")} and (orientation: landscape)`]: {
        minHeight: 80,
      },
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: coreTheme.spacing(3),
          paddingRight: coreTheme.spacing(3),
          [coreTheme.breakpoints.up("md")]: {
            paddingLeft: coreTheme.spacing(4),
            paddingRight: coreTheme.spacing(4),
          },
          [coreTheme.breakpoints.up("lg")]: {
            paddingLeft: coreTheme.spacing(5),
            paddingRight: coreTheme.spacing(5),
          },
        },
      },
    },
  },
});

if (process.env.NODE_ENV !== "production" && typeof window !== "undefined") {
  window.theme = mainTheme;
}

export default mainTheme;

import PropTypes from 'prop-types';
import React from "react";

import { InputAdornment, SvgIcon, TextField } from "@material-ui/core";

import EndAdornmentTooltip from '../../../account/src/components/EndAdornmentTooltip';

FieldTextExtended.propTypes = {
  InputProps: PropTypes.object,
  adornmentTooltip: PropTypes.node,
  className: PropTypes.string,
  error: PropTypes.bool,
  select: PropTypes.bool,
  showValidIcon: PropTypes.bool,
};

export default function FieldTextExtended({
  InputProps,
  adornmentTooltip,
  className,
  showValidIcon,
  ...props
}) {
  let classNames = className;

  if (showValidIcon) {
    classNames += ' valid';
  }

  if (props?.hiddenLabel) {
    classNames += ' hidden_label';
    delete props?.label
  }

  return (
    <TextField
      InputProps={{
        endAdornment: (
          <>
            {!props.select && (
              <InputAdornment
                position="end"
                {...(props.select && {
                  sx: {
                    position: "absolute",
                    padding: 0,
                    right: "32px",
                    top: 0,
                    bottom: 0,
                    my: "auto",
                    zIndex: 0,
                  },
                })}
              >
                {showValidIcon && (
                  <SvgIcon>
                    <path d="M17.7071 10.7071C18.0976 10.3166 18.0976 9.68342 17.7071 9.29289C17.3166 8.90237 16.6834 8.90237 16.2929 9.29289L11 14.5858L7.70711 11.2929C7.31658 10.9024 6.68342 10.9024 6.29289 11.2929C5.90237 11.6834 5.90237 12.3166 6.29289 12.7071L10.2929 16.7071C10.6834 17.0976 11.3166 17.0976 11.7071 16.7071L17.7071 10.7071Z" fill="#67C42F"/>
                    <path clipRule="evenodd" d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z" fill="#67C42F" fillRule="evenodd"/>
                  </SvgIcon>
                )}

                {props.error && (
                  <SvgIcon>
                    <path fillRule="evenodd" clipRule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17ZM13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V13C11 13.5523 11.4477 14 12 14C12.5523 14 13 13.5523 13 13V7Z" fill="#FF5C5C"/>
                  </SvgIcon>
                )}

              </InputAdornment>
            )}
            <EndAdornmentTooltip title={adornmentTooltip} />
          </>
        ),
        ...InputProps,
      }}
      autoComplete={"off"}
      className={classNames}
      fullWidth
      sx={{
        ...(props.select && {
          "& select": {
            zIndex: 1,
          },
          "& .MuiInputBase-adornedEnd": {
            paddingRight: 0,
          },
        }),
      }}
      variant={props.disabled ? 'outlined' : 'filled'}
      {...props}
    />
  );
}

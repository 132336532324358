import React from "react";

import { SvgIcon } from "@material-ui/core";

export default function IconValidField(params) {
  return (
    <SvgIcon sx={{ color: "success.main" }}>
      <path d="M9.465,16.066L6.42,13.021C6.151,12.752 6,12.388 6,12.008C6,11.628 6.151,11.264 6.42,10.996C6.98,10.435 7.885,10.435 8.445,10.996L10.47,13.021L15.555,7.936C16.115,7.376 17.02,7.376 17.58,7.936C18.14,8.496 18.14,9.401 17.58,9.961L11.49,16.051C10.93,16.626 10.025,16.626 9.465,16.066Z" />
    </SvgIcon>
  );
}

import PropTypes from "prop-types";
import React, { useEffect } from "react";

import _noop from "lodash.noop";
import { useController } from "react-hook-form";

import FIELD_TYPE_TO_COMPONENT from "./field-types-mapping";

FormFieldContainer.propTypes = {
  control: PropTypes.object,
  fieldType: PropTypes.oneOf([
    "text",
    "radio",
    "checkboxGroup",
    "checkbox",
    "nameAutocomplete",
    "legalEntityAutocomplete",
    "file",
    "autocomplete",
  ]),
  name: PropTypes.any,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  rules: PropTypes.object,
  shouldUnregister: PropTypes.bool,
};

export default function FormFieldContainer({
  control,
  name,
  shouldUnregister,
  onBlur = _noop,
  onChange = _noop,
  fieldType,
  rules,
  ...props
}) {
  const Controller = useController({ name, control, shouldUnregister, rules });

  const {
    field: { onBlur: _onBlur, onChange: _onChange, value },
  } = Controller;

  useEffect(() => {
    onChange(Controller);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function handleChange(event) {
    _onChange(event);
    onChange(Controller);
  }

  function handleBlur(event) {
    _onBlur(event);
    onBlur(Controller);
  }

  const FormFieldWrapper = FIELD_TYPE_TO_COMPONENT[fieldType];

  return (
    <FormFieldWrapper
      {...Controller}
      {...props}
      control={control}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
}

import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { Autocomplete, ListItem, ListItemText, Typography } from "@material-ui/core";
import _noop from "lodash.noop";
import debounce from "lodash/debounce";
import useFetch from "use-http";

import FieldTextExtended from "./FieldTextExtended";

const TOKEN = "640f03ef7ad1b62d099385d9b724257b51376ee7";
const DADATA_FETCH_OPTIONS = {
  method: "POST",
  mode: "cors",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Token " + TOKEN,
  },
  cachePolicy: "no-cache",
};

const dadataLegalEntityUrl = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";
function useDadata(inn) {
  const { data, error, loading, post } = useFetch(dadataLegalEntityUrl, DADATA_FETCH_OPTIONS);

  useEffect(() => {
    if (inn) {
      post({ query: inn });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inn]);

  return { data: data?.suggestions?.[0], loading, error };
}

const isOptionEqualToValue = (option, _value) => option.data?.inn === _value.data?.inn;
const getOptionLabel = option => (option ? option.value : null);

FieldAutocompleteLegalEntity.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  helperText: PropTypes.node,
  inputRef: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};
export default function FieldAutocompleteLegalEntity({
  defaultValue,
  disabled = false,
  helperText,
  inputRef,
  onBlur = _noop,
  onChange = _noop,
  ...props
}) {
  const { data: legalEntity } = useDadata(defaultValue);
  const [noOptionsText, setNoOptionsText] = React.useState("");
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);

  // TODO: Сделать обработку ошибок
  const { post } = useFetch(
    "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party",
    DADATA_FETCH_OPTIONS,
  );

  useEffect(() => {
    if (legalEntity) {
      setOptions([legalEntity]);
      setInputValue(legalEntity.value);
      setValue(legalEntity);
    }
  }, [legalEntity]);

  const fetch = React.useMemo(
    () =>
      debounce((body, callback) => {
        post(body).then(result => {
          callback(result.suggestions);
        });
      }, 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // TODO: не должно быть запроса на onChange. Надо отслеживать, если меняется inputValue из-за изменения value, то не слать в этом случае запрос.

  React.useEffect(() => {
    let active = true; // FIXME: active всегда

    if (!inputValue) {
      setNoOptionsText("Введите ИНН или название вашей компании / ИП");
      setValue(null);
      setOptions([]);
      return undefined;
    }

    fetch({ query: inputValue, status: ["ACTIVE"] }, results => {
      if (active) {
        setNoOptionsText("Поиск ...");
        let newOptions = [];

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        if (!newOptions.length) {
          setNoOptionsText("По данному запросу ничего не найдено");
        }

        setOptions(newOptions);
      }
    });

    return () => {
      setNoOptionsText("Введите ИНН или название вашей компании / ИП");

      active = false;
    };
  }, [inputValue, value, fetch]);

  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function handleChange(event, newOption) {
    if (newOption) {
      setValue(newOption);
    } else {
      setOptions([]);
      setValue(null);
    }
  }

  return (
    <Autocomplete
      autoComplete
      autoHighlight
      disableClearable
      disabled={disabled}
      filterOptions={x => x}
      // Additionally, you will need to disable the built-in filtering of the Autocomplete component by overriding the filterOptions prop:
      forcePopupIcon={false}
      // freeSolo TODO: должен ли быть свободный ввод?
      getOptionLabel={getOptionLabel}
      includeInputInList
      inputValue={inputValue}
      isOptionEqualToValue={isOptionEqualToValue}
      noOptionsText={noOptionsText}
      onChange={handleChange}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      renderInput={({ InputProps: { className, endAdornment, ...restInputProps }, ...params }) => (
        <FieldTextExtended
          {...props}
          {...params}
          InputProps={restInputProps}
          // error={showError}
          helperText={helperText || (value ? `ИНН: ${value.data.inn}` : "")}
          inputRef={inputRef}
          onBlur={onBlur}
        // showValidIcon={Boolean(value && !invalid)}
        />
      )}
      renderOption={renderOption}
      value={value}
    />
  );
}

function renderOption(props, option) {
  // TODO: сделать подсветку
  // const matches = match(option.value, "te");
  // const parts = parse(
  //   option.structured_formatting.main_text,
  //   matches.map((match) => [match.offset, match.offset + match.length]),
  // );
  const TextPrimary = (
    <Typography noWrap sx={{ fontWeight: 400 }} variant="inherit">
      {option.value}
    </Typography>
  );

  const TextSecondary = `ИНН ${option.data?.inn}`;

  return (
    <ListItem {...props} dense key={props.id}>
      <ListItemText primary={TextPrimary} secondary={TextSecondary} />
    </ListItem>
  );
}

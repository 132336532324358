import PropTypes from "prop-types";
import React from "react";

import { Alert, Collapse, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

AlertError.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
export default function AlertError({ message, onClose, open }) {
  function handleClick() {
    onClose();
  }

  return (
    <Collapse in={open}>
      <Alert
        action={
          <IconButton aria-label="close" color="inherit" onClick={handleClick} size="small">
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        severity="error"
        sx={{ mb: 3 }}
      >
        {message}
      </Alert>
    </Collapse>
  );
}

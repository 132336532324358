import PropTypes from "prop-types";
import React from "react";

import { Typography, Box } from "@material-ui/core";

ShowMoreText.propTypes = {
  defaultExpanded: PropTypes.bool,
  lines: PropTypes.number,
  text: PropTypes.node,
};

export default function ShowMoreText({ defaultExpanded = false, lines = 4, text }) {
  const [expanded, setExpand] = React.useState(defaultExpanded);

  function handleClick() {
    setExpand(!expanded);
  }
  return (
    <>
      <Typography
        sx={{
          "-webkit-line-clamp": "5",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          "-webkit-box-orient": "vertical",
          overflow: "hidden",
          ...(expanded && {
            "-webkit-line-clamp": "unset",
          }),
        }}
        variant="inherit"
      >
        {text}
      </Typography>
      {!expanded ? (
        <Box
          alignItems="center"
          css={{ cursor: "pointer" }}
          display={{ xs: "flex", lg: "none" }}
          onClick={handleClick}
          role="button"
        >
          <Typography color="primary.main" mt={2} textAlign="center" variant="subtitle2">
            Подробнее
          </Typography>
        </Box>
      ) : (
        <Box
          alignItems="center"
          css={{ cursor: "pointer" }}
          display={{ xs: "flex", lg: "none" }}
          onClick={handleClick}
          role="button"
        >
          <Typography color="primary.main" mt={2} textAlign="center" variant="subtitle2">
            Скрыть
          </Typography>
        </Box>
      )}
    </>
  );
}

/* eslint-disable react/jsx-filename-extension */
import React from "react";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import "whatwg-fetch";
import LayoutMedicineThemeProvider from "./src/layouts/LayoutMedicineThemeProvider";

const RE_CAPTCHA_KEY = "6Lc85uMZAAAAAGWrY22hwPippm6AeqczqpHwW--c";

export function wrapRootElement({ element }) {
  return <GoogleReCaptchaProvider reCaptchaKey={RE_CAPTCHA_KEY}>{element}</GoogleReCaptchaProvider>;
}

export function wrapPageElement({ element, props }) {
  if (props.uri.includes("lizing-meditsinskogo-oborudovaniya")) {
    return <LayoutMedicineThemeProvider>{element}</LayoutMedicineThemeProvider>;
  }
  return element;
}

import PropTypes from "prop-types";
import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import { responsiveFontSizes, ThemeProvider } from "@material-ui/core/styles";
import { theme } from "@product-site-frontend/shared";
import { Helmet } from "react-helmet";

const mainTheme = responsiveFontSizes(theme, { factor: 6 });

export default function TopLayout({ children, ...props }) {
  return (
    <React.Fragment>
      <Helmet>
        <meta content="minimum-scale=1, initial-scale=1, width=device-width" name="viewport" />
      </Helmet>
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        {React.cloneElement(children, props)}
      </ThemeProvider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};

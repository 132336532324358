import PropTypes from "prop-types";
import React from "react";

import { Box } from "@material-ui/core";

IconBurger.propTypes = {
  open: PropTypes.bool,
};
export default function IconBurger({ open }) {
  return (
    <Box
      sx={{
        width: "18px",
        height: "14px",
        position: "relative",
        margin: "0 auto",
        transform: "rotate(0deg)",
        transition: "0.5s ease-in-out",
        cursor: "pointer",
        "& span": {
          display: "block",
          position: "absolute",
          height: "2px",
          width: "100%",
          bgcolor: "#B6BBC2",
          borderRadius: "1px",
          opacity: 1,
          left: 0,
          transform: "rotate(0deg)",
          transition: "0.25s ease-in-out",
          "&:nth-of-type(1)": {
            top: open ? 6 : 0,
            ...(open && { width: 0, left: "50%" }),
          },
          "&:nth-of-type(2), &:nth-of-type(3)": {
            top: 6,
          },
          "&:nth-of-type(4)": {
            top: open ? 6 : 12,
            ...(open && { width: 0, left: "50%" }),
          },
          "&:nth-of-type(2)": {
            ...(open && { transform: "rotate(45deg)" }),
          },
          "&:nth-of-type(3)": {
            ...(open && { transform: "rotate(-45deg)" }),
          },
        },
      }}
    >
      <span />
      <span />
      <span />
      <span />
    </Box>
  );
}

import PropTypes from "prop-types";
import React from "react";

import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@material-ui/core";
import _noop from "lodash.noop";

FormFieldCheckboxWrapper.propTypes = {
  field: PropTypes.object,
  fieldState: PropTypes.object,
  formState: PropTypes.object,
  helperText: PropTypes.node,
  label: PropTypes.node,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};
export default function FormFieldCheckboxWrapper({
  field: { ref, value },
  fieldState: { error, invalid, isTouched },
  formState,
  helperText,
  label,
  onBlur = _noop,
  onChange = _noop,
  ...props
}) {
  // const showError = !!(isTouched && invalid);
  const showError = !!invalid;

  return (
    <FormControl component="fieldset" error={showError} sx={{ mb: 3 }}>
      <FormControlLabel
        {...props}
        checked={value}
        control={<Checkbox sx={{ marginTop: "-10px" }} />}
        inputRef={ref}
        label={label}
        onBlur={onBlur}
        onChange={onChange}
        sx={{ alignItems: "flex-start" }}
        value={value}
      />
      <FormHelperText variant="standard">
        {(showError && error?.message) || helperText}
      </FormHelperText>
    </FormControl>
  );
}

import PropTypes from 'prop-types';
import React from 'react';

import { Typography, Container } from '@material-ui/core';

const sx = {
  container: {
    my: { xs: 4, lg: 7 },
    pb: { xs: 2, lg: 4 },
  },
  heading: {
    mb: { xs: 4, md: 5, lg: 6 },
  },
};

AboutSectionContainer.propTypes = {
  children: PropTypes.node,
  sxContainer: PropTypes.object,
  title: PropTypes.node,
};
export default function AboutSectionContainer({
  children,
  sxContainer,
  title,
  ...props
}) {
  return (
    <Container
      component="section"
      maxWidth="lg"
      sx={{ ...sx.container, ...sxContainer }}
      {...props}
    >
      {title && (
        <Typography sx={sx.heading} variant="h2">
          {title}
        </Typography>
      )}
      {children}
    </Container>
  );
}
import PropTypes from "prop-types";
import React from "react";

import IMask from "imask";
import { IMaskInput } from "react-imask";

const defaultMask = "+{7} (000) 000-00-00";

export const maskedPhone = IMask.createMask({ mask: defaultMask });

// eslint-disable-next-line react/display-name
const PhoneMaskInput = React.forwardRef(({ mask = defaultMask, onChange, ...props }, ref) => {
  return (
    <IMaskInput
      {...props}
      inputRef={ref}
      mask={mask}
      onAccept={(value) => onChange(value)}
      prepare={(appended, masked) => {
        if (appended === "8" && masked.value === "") {
          return "";
        }
        return appended;
      }}
    />
  );
});

PhoneMaskInput.propTypes = {
  mask: PropTypes.string,
  onChange: PropTypes.func,
};

export default PhoneMaskInput;
